body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);

    
    background-repeat: no-repeat;
    background-attachment: fixed;
}

html {
    height: 100%;
}

.App{
    display: flex;
    min-height: 100vh;
    
    flex-direction: column;
}

.nav1 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
}

.logo {
    height: 200px;
    width: 200px;
}

.link {
    padding: 25px;
    text-decoration: none;
    color: black;
    font-size: 25px;
    transition: 0.5s;
    font-family: 'Caveat', cursive;;
}

.link:active,
.link:visited {
    color: black;
}

.link:hover {
    color: slategray;
}

.linkProject {
    padding: 25px;
    text-decoration: none;
    color:firebrick;
    font-size: 17px;
    transition: 0.5s;
}

.linkProject:active,
.linkProject:visited {
    color: firebrick;
}

.linkProject:hover {
    color: slategray;
}

.modalText{
    font-size: medium;
}

.mainTop {
    display: flex;
    justify-content: center;
}

.welcome {
    padding-bottom: 10px;
    font-size: 20px;
    text-align: center;
    width: 55%;
}

.iconContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mainTech {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 25px;
}

.techIcon {
    height: 100px;
    width: 100px;
    padding: 25px;
}

.techMessage p {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 0;
}

.techDesc {
    font-weight: 800;
}

.projectIcon{
    height: 300px;
    width: 500px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    margin-bottom: 15px;
    transition: all .2s ease-in-out; 
}

.projectIcon:hover{
    transform: scale(1.2);
    cursor: pointer;
}

.flexHeight{
    flex: 1;
}

.footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
}

.footerLink img {
    height: 25px;
    width: 25px;
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.messageArea{
    height: 25vh;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.container{
    display:flex;
    justify-content: center;
}

.hidden {
    visibility: hidden;
    font-size: 35px;
}

.show {
    visibility: visible;
    font-size: 35px;
}

:focus {
    outline: none;
}

.col-3 {
    float: left !important;
    width: 50% !important;
    margin: 40px 3% !important;
    position: relative !important;
}


/* necessary to give position: relative to parent. */

input[type="text"] {
    color: white;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
}

input[type="email"] {
    color: white;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
}

textarea {
    color: white;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
}

.effect-9 {
    border: 1px solid #ccc;
    padding: 7px 14px 9px;
    transition: 0.4s;
    background-color: slategray;
    color: white;
}

.effect-9:focus{
    box-shadow: 5px 5px 15px -1px #873760;
    transition: 0.4s;
}

::placeholder{
    color: snow;
}

.effect-9~.focus-border:before,
.effect-9~.focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: white;
    transition: 0.2s;
    transition-delay: 0.2s;
}

.effect-9~.focus-border:after {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    transition-delay: 0.6s;
}

.effect-9~.focus-border i:before,
.effect-9~.focus-border i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: white;
    transition: 0.2s;
}

.effect-9~.focus-border i:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
    transition-delay: 0.4s;
}

.effect-9:focus~.focus-border:before,
.effect-9:focus~.focus-border:after {
    width: 100%;
    transition: 0.2s;
    transition-delay: 0.6s;
}

.effect-9:focus~.focus-border:after {
    transition-delay: 0.2s;
}

.effect-9:focus~.focus-border i:before,
.effect-9:focus~.focus-border i:after {
    height: 100%;
    transition: 0.2s;
}

.effect-9:focus~.focus-border i:after {
    transition-delay: 0.4s;
}

.messageInput {
    width: 50%;
    height: 50px;
}

.button{
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    -webkit-box-shadow: 2px 2px 6px #121012;
    -moz-box-shadow: 2px 2px 6px #121012;
    box-shadow: 2px 2px 6px #121012;
    font-family: Georgia;
    color: #ffffff;
    font-size: 20px;
    background: slategray;
    padding: 10px 20px 10px 20px;
    border: solid #ffffff 2px;
    text-decoration: none;
    transition: 0.5s;
  }
  
  .button:hover {
    background: black;
    text-decoration: none;
    transition: 0.5s;
  }

@media screen and (max-width: 600px) {
    .techIcon {
        height: 75px;
        width: 75px;
        padding: 15px;
    }

    .techs{
        font-size: 14px;
    }
    .nav1,
    .footer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 15px;
        
    }
    .welcome {
        font-size: 16px;
        text-align: center;
    }
    .link {
        padding: 15px;
        text-decoration: none;
        color: black;
        font-size: 20px;
        transition: 0.3s;
        text-decoration: underline;
    }
    .link:active,
    .link:visited {
        color: black;
    }


    .projectIcon{
        height: 200px;
        width: 300px;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
        margin-bottom: 15px;
        transition: all .2s ease-in-out; 
    }
}